<template>
  <v-container v-if="profile">
    <div v-if="stripeCustomerInitialized">
      <v-row class="text-center">
        <v-col cols="12">
          <v-btn fab dark color="primary" @click.prevent="refresh">
            <v-icon dark>cached</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="10">
          <v-card>
            <v-list three-line subheader>
              <v-subheader>Payment Methods</v-subheader>
              <v-list-item v-for="(source, id) in sources" :key="id">
                <v-list-item-content v-if="source.id">
                  <v-list-item-title class="align-center">
                    <v-icon color="indigo"
                      >fab fa-cc-{{ source.brand | lowercase }}</v-icon
                    >

                    {{ source.brand }} &hellip;{{ source.last4 }} (exp.
                    {{ source.exp_month }}/{{ source.exp_year }})
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else-if="source._id !== 'ignore'">
                  <v-list-item-title>
                    <v-icon color="error">warning</v-icon>
                    Problem detected:
                    <p v-if="source.error">{{ source.error }}</p>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="source._id !== 'ignore'">
                  <v-list-item-action-text>
                    <v-btn
                      small
                      color="red"
                      @click.prevent.stop="deleteSrc(source._id)"
                      text
                      >Delete</v-btn
                    >
                    <v-btn
                      small
                      v-if="false"
                      color="indigo"
                      @click.prevent.stop="setDefaultSrc(source._id)"
                      text
                      >set default</v-btn
                    >
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>
      <v-row>
        <v-col class="pt-3" cols="12" sm="9">
          <v-card>
            <v-list three-line subheader>
              <v-subheader>Add Credit Card</v-subheader>

              <v-list-item-content>
                <v-list-item-title>
                  <card
                    class="mx-2"
                    :stripe="stripe"
                    :options="stripeOptions"
                    @change="complete = $event.complete"
                  />
                </v-list-item-title>
                <v-list-item-subtitle> </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  class="ma-2"
                  small
                  color="info"
                  @click="pay"
                  :disabled="!complete"
                  >Add your credit card</v-btn
                >
              </v-list-item-action>
            </v-list>

            {{ newCreditCard.error }}
          </v-card>
        </v-col>
      </v-row>

      <div v-if="false">
        <v-btn v-on:click="submitNewCreditCard">Add</v-btn>
        {{ newCreditCard.error }}
      </div>
      <v-spacer></v-spacer>
    </div>

    <div v-if="!stripeCustomerInitialized && user.emailVerified">
      <v-alert outlined color="error" icon="info" :value="true"
        >{{ $t('Common.payment_account_enable') }}
      </v-alert>
      Please review
      <a
        rel="nofollow"
        target="_blank"
        content="noindex"
        href="https://storage.googleapis.com/download.takein.com/apps/PRIVACYPOLICY.html"
        >Privacy Policy</a
      >
      and
      <a
        rel="nofollow"
        target="_blank"
        content="noindex"
        href="https://storage.googleapis.com/download.takein.com/apps/TakeIn-TERMSOFUSE-5.16.2019.html"
        >Term of use</a
      >
      then:
      <v-checkbox
        label="Accepting Terms and Conditions"
        v-model="acceptedAccountTerms"
        color="light-green"
        value="true"
        hide-details
      ></v-checkbox>

      <v-btn
        color="primary"
        raised
        :disabled="!acceptedAccountTerms"
        @click.prevent="activateAccount"
        >Enable Payment Account</v-btn
      >
    </div>
    <profile-status></profile-status>
    <v-btn class="primary white--text" @click="$router.go(-1)" icon>
      <v-icon>chevron_left</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileStatus from 'fod-core/src/components/profile/ProfileStatus.vue'

import { Card, createToken } from 'vue-stripe-elements-plus'

// @vue/component
export default {
  components: { Card, ProfileStatus },

  data() {
    return {
      stripe: process.env.VUE_APP_STRIPE_CLIENT_TOKEN,
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      postalCode: false,
      submitted: false,
      status: '',
      response: '',

      stripeOptions: {
        // you can configure that cc element. I liked the default, but you can
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      acceptedAccountTerms: false,
      currentUser: null,
      newCreditCard: {
        number: '4242424242424242',
        cvc: '111',
        exp_month: 1,
        exp_year: 2020,
        address_zip: '00000'
      },
      charges: {},
      newCharge: {
        source: null,
        amount: 2000
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile',
      newUser: 'newUser',
      isChef: 'isChef'
    }),
    sources() {
      return this.$store.getters.sources
    },

    stripeCustomerInitialized() {
      console.log('ss', this.$store.getters.stripeCustomerInitialized)
      return this.$store.getters.stripeCustomerInitialized
    }
    // stripeKey() {
    //   return process.env.VUE_APP_STRIPE_CLIENT_TOKEN
    // }
  },
  mounted() {
    this.$store.dispatch('checkAccount')
     this.includeStripe('js.stripe.com/v3/', function(){
        this.configureStripe();
    }.bind(this) );
  },
  created() {
    this.$store.dispatch('touchPayment')
  },

  methods: {
    refresh() {
      this.$store.dispatch('checkAccount', { charges: true })
      this.$store.dispatch('getCharges')
    },
    activateAccount() {
      this.$store.dispatch('activateAccount')
    },
    deleteSrc(id) {
      console.log('Deleting source', id)
      this.$store.dispatch('deleteSource', id)
    },
    setDefaultSrc(id) {
      console.log('setting default source', id)
    },
    pay() {
      console.log('Paying')
      createToken()
        .then(data => {
          this.submitted = true
          console.log(data.token) //this is a token we would use for the stripeToken below
          this.$store.dispatch('addSource', data.token)
        })
        .catch(error => {
          console.log(error)
        })
    },
    submitNewCreditCard: function() {
      createToken().then({
        number: this.newCreditCard.number,
        cvc: this.newCreditCard.cvc,
        exp_month: this.newCreditCard.exp_month,
        exp_year: this.newCreditCard.exp_year,
        address_zip: this.newCreditCard.address_zip
      }),
        (status, response) => {
          if (response.error) {
            this.newCreditCard.error = response.error.message
          } else {
            /*firebase.database().ref(`/stripe_customers/${this.currentUser.uid}/sources`).push({token: response.id}).then(() => {
                  this.newCreditCard = {
                    number: '',
                    cvc: '',
                    exp_month: 1,
                    exp_year: 2017,
                    address_zip: ''
                  };
                });*/
          }
        }
    },
    includeStripe( URL, callback ){
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = '//' + URL;
                if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                scriptTag.parentNode.insertBefore(object, scriptTag);
            },
  }
}
</script>
